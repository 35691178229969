<template>
	<div class="page edit-page">
		<div class="top-wrapper">
			<div class="title"> {{ 85 > scrollTop ? title : '' }}</div>
			<div class="actions">
				<el-button type="primary"
						   plain
						   size="small"
						   class="cancel"
						   @click="goBack"> 取消
				</el-button>
				<el-button type="primary"
						   size="small"
						   class="submit"
						   @click="submit"> 确定
				</el-button>
			</div>
		</div>

		<div class="content-wrapper">
			<el-row :gutter="24"
					type="flex"
					class="content-row">
				<el-col>
					<div class="form-container pa20 pb0">
						<el-form ref="form"
								 :model="form"
								 :rules="rules">
							<div class="inline-form-item"
								 v-if="!isCase">
								<el-form-item label="标题"
											  prop="title">
									<el-input v-model="form.title"
											  placeholder="请输入"
											  maxlength="100"
											  show-word-limit
											  class="show-word-pr-3"
											  @blur="form.title=form.title.trim()"></el-input>
								</el-form-item>

								<el-form-item label="类型"
											  prop="type">
									<el-select v-model="form.type"
											   placeholder="请选择">
										<el-option v-for="option in bbsTypeOptions()"
												   :key="option.id"
												   :label="option.name"
												   :value="option.id"></el-option>
									</el-select>
								</el-form-item>
							</div>

							<el-form-item label="标题"
										  prop="title"
										  v-else>
								<el-input v-model="form.title"
										  placeholder="请输入"
										  maxlength="100"
										  show-word-limit
										  class="show-word-pr-3"
										  @blur="form.title=form.title.trim()"></el-input>
							</el-form-item>

							<div class="inline-form-item">
								<el-form-item label="机种分类"
											  prop="deviceNames">
									<el-select v-model="form.deviceNames"
											   filterable
											   clearable
											   placeholder="请选择">
										<el-option v-for="option in modelTypeOptions"
												   :key="option.id"
												   :label="option.name"
												   :value="option.name"></el-option>
									</el-select>
								</el-form-item>

								<el-form-item label="机型分类"
											  prop="deviceInfo">
									<el-select v-model="form.deviceInfo"
											   filterable
											   clearable
											   placeholder="请选择">
										<el-option v-for="option in deviceTypeOptions"
												   :key="option.id"
												   :label="option.name"
												   :value="option.name"></el-option>
									</el-select>
								</el-form-item>
							</div>

							<div class="inline-form-item">
								<el-form-item label="机型名称"
											  prop="modelInfo">
									<el-select v-model="form.modelInfo"
											   filterable
											   clearable
											   placeholder="请选择"
											   @change="handleDeviceType">
										<el-option v-for="option in deviceNameOptions"
												   :key="option.id"
												   :label="option.name"
												   :value="option.name"></el-option>
									</el-select>
								</el-form-item>

								<el-form-item label="问题类型"
											  prop="quesType">
									<el-select v-model="form.quesType"
											   placeholder="请选择">
										<el-option v-for="option in bbsQuesTypeOptions()"
												   :key="option.id"
												   :label="option.name"
												   :value="option.id"></el-option>
									</el-select>
								</el-form-item>
							</div>
							<el-form-item label="图片/视频链接">
								<div class="inline-form-item full-width">
									<el-form ref="firmware-form" :model="downloadItem" :rules="firmwareRules"
											 class="firmware-form">
										<el-form-item>
											<el-input v-model="downloadItem.firmwareName" placeholder="请输入文字描述"
													  clearable class="mr10 show-word-clearable-pr-2" maxlength="50"
													  show-word-limit
													  @blur="downloadItem.firmwareName=downloadItem.firmwareName.trim()">
											</el-input>
										</el-form-item>
										<el-form-item prop="firmwareLink">
											<el-input v-model="downloadItem.firmwareLink"
													  placeholder="请输入Link(http://... 或 https://...)" clearable
													  maxlength="2000" class="mr10"
													  @blur="downloadItem.firmwareLink=downloadItem.firmwareLink.trim()">
											</el-input>
										</el-form-item>
									</el-form>
									<el-button class="firmware-btn"
											   :disabled="!downloadItem.firmwareName || !downloadItem.firmwareLink"
											   @click="addDownloadItem"> 添加 </el-button>
								</div>
								<div v-for="(downloadItem, index) in form.firmwareList" :key="index"
									 class="link-wrapper">
									<el-tag type="info" closable class="link-tag"
											@close="removeDownloadItem(downloadItem)">
										{{ downloadItem.firmwareName }} : {{ downloadItem.firmwareLink }}
									</el-tag>
								</div>
							</el-form-item>
							<el-form-item label="附件"
										  prop="fileIdList">
								<el-upload
									:action="fileUploadPath"
									:headers="headers"
									:multiple="true"
									:show-file-list="true"
									:file-list="attachFileList"
									:before-upload="handleBeforeAttachFileUpload"
									:on-success="handleFileUploaded"
									:on-remove="handleFileRemove"
									:disabled="attachFileList.length >= fileLimit"
									accept=".mp4,.wmv,.avi,.m4v,.mov,.jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx,.zip">
									<el-button icon="el-icon-paperclip"
											   :disabled="attachFileList.length >= fileLimit">上传文件
									</el-button>
									<!--<div slot="tip"-->
									<!--	 class="el-upload__tip">(单个文件不超过150M)</div>-->
									<div v-if="isCase" slot="tip" class="el-upload__tip">(最多可上传{{ fileLimit }}个, 每个文件不超过150M)</div>
									<div v-else slot="tip" class="el-upload__tip">(最多可上传{{ fileLimit }}个, 每个文件不超过50M)</div>
								</el-upload>
							</el-form-item>
							<el-form-item label="正文"
										  prop="content"
										  class="editor-wrapper">
								<RichTextEditor :content.sync="form.content"
												placeholder="请输入正文"></RichTextEditor>
							</el-form-item>

						</el-form>
					</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
import Upload from '@/components/Upload/index.vue'
import RichTextEditor from '@/components/RichText/RichTextEditor.vue'
import Progress from '@/components/Progress/index.vue'
import {debounce} from '@/utils/debounce'
import FileList from "@/views/claimantManagement/repairRequest/components/FileList.vue";
import defaultSettings from "@/settings";

import {bbsQuesTypeOptions, bbsTypeOptions} from "@/constant/options"

const url = defaultSettings.hostName

export default {
	components: {
		FileList,
		Upload,
		Progress,
		RichTextEditor,
		bbsQuesTypeOptions,
		bbsTypeOptions
	},
	data() {
		return {
			// 公告ID
			id: '',
			// 新增和编辑后返回列表页，需要还原列表页搜索条件
			cached: {
				pageNo: 1,
				searchKey: '',
				type: '',
			},
			form: {
				title: '',
				type: '', // 类型 1：问题 2：经验 3: 案例
				content: '',
				fileIdList: [],
				videoLinks: [],
				modelInfo: '', //机型名称
				deviceInfo: '', //机型分类
				deviceNames: '', //机种分类
				quesType: '',
				fileFlag: '', // 是否有附件
				videoFileFlag: '', // 是否有视频
				deviceIds: '',
				firmwareList: [],
			},
			downloadItem: {
				firmwareName: '',
				firmwareLink: ''
			},
			rules: {
				content: [{
					required: true,
					message: '请输入正文',
					trigger: 'blur'
				}],
				type: [{
					required: true,
					message: '请选择类型',
					trigger: 'change'
				}],
				title: [{
					required: true,
					message: '请输入标题',
					trigger: 'change'
				}],
				deviceNames: [{
					required: true,
					message: '请选择机种分类',
					trigger: 'change'
				}],
				deviceInfo: [{
					required: true,
					message: '请选择机型分类',
					trigger: 'change'
				}],
				modelInfo: [{
					required: true,
					message: '请选择机型名称',
					trigger: 'change'
				}],
				quesType: [{
					required: true,
					message: '请选择问题类型',
					trigger: 'change'
				}]
			},
			firmwareRules: {
				firmwareLink: [{
					type: 'url',
					message: '请输入正确的地址',
					trigger: 'blur'
				}]
			},
			scrollTop: 0,
			fileUploadPath: '',
			deviceVisible: false,
			// 附件文件列表
			attachFileList: [],
			// 机种分类选项
			modelTypeOptions: [],
			// 机型分类选项
			deviceTypeOptions: [],
			// 机型名称选项
			deviceNameOptions: [],
			// 是否案例
			isCase: true,
			// 缓存参数
			cacheParams: {}
		}
	},
	watch: {
		deviceNames(val, oldVal) {
			if (oldVal) {
				this.form.deviceInfo = ''
				this.form.deviceIds = ''
				this.form.modelInfo = ''
			}

			let options = []
			for (const model of this.modelTypeOptions) {
				if (model.name === val) {
					options = model.children || []
					break
				}
			}
			this.deviceTypeOptions = options
		},
		deviceInfo(val, oldVal) {
			if (oldVal) {
				this.form.deviceIds = ''
				this.form.modelInfo = ''
			}

			let options = []
			for (const device of this.deviceTypeOptions) {
				if (device.name === val) {
					options = device.children || []
					break
				}
			}
			this.deviceNameOptions = options
		},
		initialData: true
	},
	computed: {
		title() {
			if (this.isCase) {
				if (this.id) return '修改案例'
				return '新增案例'
			} else {
				if (this.id) return '修改内容'
				return '新增内容'
			}
		},
		headers() {
			const token = sessionStorage.getItem('token') || ''
			return {
				token: token
			}
		},
		fileLimit() {
			// 最大上传文件数
			return 10
		},
		deviceNames() {
			return this.form.deviceNames
		},
		deviceInfo() {
			return this.form.deviceInfo
		}
	},
	created() {
		// 案例管理
		if (this.$route.query.menuAction === 'caseManagement') {
			this.isCase = true
			this.form.type = '3'
		} else {
			this.isCase = false
		}

		// 缓存参数
		if (this.$route.params) {
			this.cacheParams = this.$route.params
		}

		this.getModelDevice()
		this.id = this.$route.query.id
		if (this.id) this.getDetail()
	},
	methods: {
		bbsTypeOptions() {
			return bbsTypeOptions
		},
		bbsQuesTypeOptions() {
			return bbsQuesTypeOptions
		},
		// 获取数据
		getDetail() {
			if (!this.id) return

			let that = this
			that.$request.post(
				"bbsInfoReplyInfo",
				true, {
					id: that.id
				},
				function (r) {
					if (r.code == "0") {
						that.form.title = r.data.title
						that.form.content = r.data.content
						that.form.videoLinks = r.data.videoLinks ? r.data.videoLinks.split(',') : []
						that.form.quesType = r.data.quesType

						that.$nextTick(() => {
							that.form.deviceNames = r.data.deviceNames
							that.form.modelInfo = r.data.modelInfo
							that.form.deviceInfo = r.data.deviceInfo
							that.form.type = r.data.type

							const files = r.data.sysAttachFileList || []
							that.attachFileList = files.map(item => {
								return that.getFinishedFile(item)
							})
							that.form.fileIdList = that.attachFileList.map(item => {
								return item.id
							})
							that.form.firmwareList = []
							for (const firmware of r.data.firmwareList) {
								const f = {
									firmwareName: firmware.firmwareName,
									firmwareLink: firmware.firmwareLink
								}
								that.form.firmwareList.push(f)
							}
						})

					} else {
						that.$message.error(r.msg)
					}
				}
			)
		},
		// 添加固件下载地址
		addDownloadItem() {
			this.downloadItem.firmwareName = this.downloadItem.firmwareName.trim()
			this.downloadItem.firmwareLink = this.downloadItem.firmwareLink.trim()

			this.$refs['firmware-form'].validate((valid) => {
				if (valid) {
					const obj = Object.assign({}, this.downloadItem)
					this.form.firmwareList.push(obj)
					this.downloadItem.firmwareName = ''
					this.downloadItem.firmwareLink = ''
				} else {
					return false
				}
			})
		},
		// 删除固件下载地址
		removeDownloadItem(item) {
			this.form.firmwareList.splice(this.form.firmwareList.indexOf(item), 1)
		},
		// 新增|修改
		submit: debounce(function () {
			this.$refs['form'].validate((valid) => {
				if (valid) {
					if (this.id) {
						// 修改
						const data = {
							...this.form,
							id: this.id,
							videoLinks: this.form.videoLinks ? this.form.videoLinks.join(',') : '',
							fileFlag: this.form.fileIdList.length > 0 ? 1 : 0,
							videoFileFlag: this.checkVideoFileType(this.attachFileList) ? 1 : 0
						}
						let that = this
						that.$request.post(
							"bbsMainInfoUpdate",
							true,
							data,
							function (r) {
								if (r.code == "0") {
									that.$message.success('保存成功')
									that.goBack()
								} else {
									that.$message.error(r.msg)
								}
							}
						)
					} else {
						// 新增
						let that = this
						that.$request.post(
							"bbsMainInfoAdd",
							true,
							{
								...that.form,
								videoLinks: that.form.videoLinks ? that.form.videoLinks.join(',') : '',
								fileFlag: that.form.fileIdList.length > 0 ? 1 : 0,
								videoFileFlag: this.checkVideoFileType(this.attachFileList) ? 1 : 0
							},
							function (r) {
								if (r.code == "0") {
									that.$message.success('保存成功')
									that.goBack()
								} else {
									that.$message.error(r.msg)
								}
							}
						)
					}
				} else {
					return false
				}
			})
		}, 1000),
		goBack() {
			this.$router.push({
				name: this.$route.query.menuAction,
				params: {
					...this.cacheParams
				}
			})
		},
		// 处理吸顶时title隐藏
		handleScroll() {
			this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
		},
		handleBeforeAttachFileUpload(file) {
			// 上传前检查
			let limitSize = file.size / 1024 / 1024 <= 150
			let msg = "单个文件不超过150M"
			// 内容管理
			if (!this.isCase) {
				limitSize = file.size / 1024 / 1024 <= 50
				msg = "单个文件不超过50M"
			}
			if (!limitSize) {
				this.$message.warning(msg)
				return false
			}
			//  文件/图片上传路径
			const extension = file.name.split('.').pop().toLowerCase();
			const videoExtensions = ['mp4', 'wmv', 'avi', 'm4v', 'mov'];
			if (!videoExtensions.includes(extension)) {
				//  文件/图片上传路径
				return new Promise((resolve) => {
					this.$nextTick(() => {
						this.fileUploadPath = url + "bsi/sysAttachFile/addRepairFileOss"
						resolve();
					})
				})
			} else {
				return new Promise((resolve) => {
					// 视频上传路径
					this.$nextTick(() => {
						this.fileUploadPath = url + "bsi/sysAttachFile/addVideoFileOss"
						resolve();
					})
				})
			}
		},
		// 上传附件成功
		handleFileUploaded(response, file, fileList) {
			this.attachFileList = fileList
			this.form.fileIdList = fileList.map(item => item.response.data.id)
		},
		handleFileRemove(file, fileList) {
			this.attachFileList = fileList
			this.form.fileIdList = fileList.map(item => item.response.data.id)
		},
		getFinishedFile(file) {
			return {
				id: file.id,
				name: file.fileName,
				url: file.fileAddr,
				size: file.fileSize,
				status: 'finished', // 文件上传状态，可以是 'uploading', 'finished', 'error'
				response: {} // 服务器响应
			}
		},
		// 获取机种分类
		getModelDevice() {
			let that = this
			that.$request.post(
				"sysModelDevice",
				true, {
					libraryDeviceWithout: "library"
				},
				function (r) {
					if (r.code == "0") {
						that.modelTypeOptions = r.data
					} else {
						that.$message.error(r.msg);
					}
				}
			)
		},
		handleDeviceType(value) {
			const selectedItem = this.deviceNameOptions.find(option => option.name === value);
			this.form.deviceIds = selectedItem.id;
		},
		// 文件类型
		checkVideoFileType(files) {
			// 循环检查files 数组内,文件名后缀是否为视频文件
			for (let i = 0; i < files.length; i++) {
				const file = files[i];
				const extension = file.name.split('.').pop().toLowerCase();
				const videoExtensions = ['mp4', 'wmv', 'avi', 'm4v', 'mov'];
				if (videoExtensions.includes(extension)) {
					return true;
				}
			}
			return false;
		},
	},
	mounted() {
		window.addEventListener('scroll', this.handleScroll);
	},
	destroyed() {
		window.removeEventListener('scroll', this.handleScroll)
	},
}

</script>

<style lang="scss"
	   scoped>
/deep/ .el-upload {
	width: 100%;
	text-align: left;

	.el-upload-dragger {
		width: 100%;
	}
}

@media screen and (max-width: 767px) {
	.content-row {
		flex-direction: column;
	}
}

</style>
